import React from 'react'
//import aboutImg1 from '../../assets/images/about/about-img1.png'

const Content = () => {
    return (
        <div className="upcoming-bootcamps-area ptb-70">
            <div className="container">
                <div className="upcoming-bootcamps-content">
                    <h2 className="h2-top bottom">Digitalisez votre plan de sécurité alimentaire</h2>
                    <p>Un véritable outil d’aide à la décision qui vous permet de maîtriser votre système de management et de le rendre <span className='bold'>plus lisible, réactif et collaboratif</span>.<br></br>
                    <span className='bold color-compliance'>Wispeek</span> garantit la <span className='bold'>confidentialité des informations échangées</span>. Facilitez la communication entre vos collaborateurs et sécurisez l’accès de l’information aux utilisateurs selon <span className='bold'>différents niveaux d’autorisation d’accès</span>.</p>
                    <p>Ce dispositif vous permet de recevoir et traiter des alertes ainsi que de diffuser à l’ensemble de vos collaborateurs des procédures de prévention.</p>
                    <p><span className='bold'>Plateforme accessible en mode déconnecté</span>, pour assurer la continuité de l’activité et simplifier le pilotage de votre performance.</p>
                    <p><span className='bold'>Notre outil satisfait aux normes de certification de la sécurité alimentaire</span>. Il est adapté à toutes les entreprises fabriquant et/ou transformant des produits alimentaires, boissons, ingrédients/aditifs, aliments pour animaux.<br></br>
                    Nous accompagnons également les fabricants d’emballages, les compagnies de transport et de stockage, le secteur du détail et les établissements de restauration.</p>
                </div>
            </div>
        </div>
    )
}

export default Content;