import React from 'react'

const Video = () => {
    return (
        <div className="upcoming-bootcamps-area pb-30 pt-70">
            <div className="container">
                <div className='center'>
                <iframe width="647" height="364" src="https://www.youtube.com/embed/_CuED6f8z4c?rel=0" title="La minute Wispeek : Food Safety Culture" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    )
}

export default Video;