import React from 'react'
import { Link } from 'gatsby'
//import img12 from '../../assets/images/about/about-img12.png'
import shape1 from '../../assets/images/about/about-shape1.png'
//import shape2 from '../../assets/images/our-mission/our-mission-shape2.png'
import starIcon from '../../assets/images/star-icon.png'
import imgFSC from '../../assets/images/food-safety.png'

const ModFSC = () => {
    return (
        <div className="about-area ptb-70 bg-fsc">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-img">
                            <img src={imgFSC} alt="banner" />
                            <div className="shape">
                                <img src={shape1} alt="banner" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    Wispeek Food Safety Culture
                                </span>
                                <h2>Food Safety Culture</h2>
                                <h3>Maîtriser votre démarche de culture de la qualité et de la sécurité des aliments</h3>
                                <p>Nous travaillons étroitement avec des entreprises de l’agroalimentaire de toutes tailles avec lesquelles nous avons développé une solution à la fois <span className='bold'>conforme aux référentiels IFS, BRC et FSSC 22 OOO, HACCP et aux attentes métiers de ce secteur</span>.</p>
                                <p><span className='bold color-compliance'>Wispeek</span> facilite la gestion de vos normes d’hygiène et de sécurité alimentaire qui permet d’assurer la qualité de vos produits et in fine la santé des consommateurs. De plus, l’outil permet de <span className='bold'>certifier la traçabilité et la sécurité de vos données</span>.</p>
                                <p>Une <span className='bold'>solution collaborative</span> qui permet d’impliquer l’ensemble des vos équipes pour faire de votre culture qualité et sécurité des aliments une priorité au sein de votre entreprise.</p>
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Demandez une démo <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModFSC