import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import PageBanner from '../components/Common/PageBanner'
import UnProjet from "../components/Common/UnProjet"
import Content from "../components/FoodSafetyCulture/Content"
import ContentTwo from "../components/FoodSafetyCulture/ContentTwo"
import WhyWispeek from "../components/FoodSafetyCulture/WhyWispeek"
import ModFSC from "../components/FoodSafetyCulture/ModFSC"
import CartesAvantages from "../components/FoodSafetyCulture/CartesAvantages"
import { Helmet } from "react-helmet"
import ContentThree from "../components/FoodSafetyCulture/ContentThree"
import LienQuestionnaire from "../components/FoodSafetyCulture/LienQuestionnaire"
import Video from '../components/FoodSafetyCulture/Video'

const FoodSafetyCulture = () => {
  return (
    <Layout>
      <Helmet>
        <title>Wispeek by Seekreet | Plateforme de mise en conformité Food Safety Culture</title>
        <meta name="description" content="Maîtriser vos processus liés à la qualité et la sécurité alimentaire afin de certifier la traçabilité et la sécurité de vos produits et données." />
      </Helmet>
      <Navbar />
      <PageBanner
          pageTitle="Wispeek - Food Safety Culture" 
          homePageText="Accueil" 
          homePageUrl="/"
          parentActive="Wispeek" 
          parentPageUrl="/wispeek" 
          activePageText="Food Safety Culture" 
      />
      <ModFSC />
      <Content />
      <ContentTwo />
      <div className="bg-fsc"><ContentThree /></div>
      <Video />
      <LienQuestionnaire />
      <WhyWispeek />
      <CartesAvantages />
      <UnProjet />
      <Footer />
    </Layout>
  )
}

export default FoodSafetyCulture