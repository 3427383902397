import React from 'react'
// import mission2 from '../../assets/images/our-mission/our-mission2.png'
// import shape1 from '../../assets/images/our-mission/our-mission-shape1.png'

const CartesAvantages = () => {
    return (
        <div className="our-mission-area ptb-70">
            <div className="container">
                <div className="row" id="fsc">
                    <div className="col-lg-2 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i class='bx bxs-user-check color-food-safety' ></i>
                            </div>
                            <h3>
                                Ergonomique
                            </h3>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i class='bx bxs-lock color-food-safety' ></i>
                            </div>
                            <h3>
                                Sécurisé
                            </h3>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i class='bx bxs-rocket color-food-safety' ></i>
                            </div>
                            <h3>
                                Evolutif
                            </h3>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i class='bx bxs-arrow-from-left color-food-safety'></i>
                            </div>
                            <h3>
                                Traçabilité
                            </h3>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i class='bx bxs-map-pin color-food-safety'></i>
                            </div>
                            <h3>
                                <span>100%</span>&nbsp;Français
                            </h3>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i class='bx bx-wifi-off color-food-safety'></i>
                            </div>
                            <h3>
                                <span>Hors&nbsp;connexion</span>
                            </h3>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CartesAvantages