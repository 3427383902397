import React from 'react'
import serviceShape4 from '../../assets/images/services/service-shape4.png'
import gestiondoc from '../../assets/images/icones/gestion-doc.gif'
import remontees from '../../assets/images/icones/remontees.gif'
import audit from '../../assets/images/icones/audit.gif'
import plan from '../../assets/images/icones/plan.gif'
import decisions from '../../assets/images/icones/decisions.gif'

const ContentTwo = () => {
    return (
        <section className="featured-services-area pt-30 pb-70">
            <div className='container custom-box'>
                <div className="row" id="food-safety">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={gestiondoc} alt="services" />
                            </div>
                            <h3>
                            Gestion documentaire
                            </h3>
                            <p>Centralisez votre gestion documentaire sur <span className='bold'>un seul outil</span> dans des bibliothèques digitales dédiées. Tous vos documents sont réunis au même endroit, ce qui facilite leur mise à jour et leur consultation. L’avantage de notre solution c’est que vos fichiers sont <span className='bold'>accessibles à tout moment et de partout</span> et donc facilite le travail de vos collaborateurs.</p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                            <div className="overview-box"> 
                                <div className="overview-content">
                                    <div className="content">
                                        <div className="features-text">
                                            <h4><i className="flaticon-tick"></i> Référentiels qualité et sécurité alimentaire</h4>
                                            <h4><i className="flaticon-tick"></i> Procédures et instructions</h4>
                                            <h4><i className="flaticon-tick"></i> Archivage des rapports</h4>
                                            <h4><i className="flaticon-tick"></i> Édition et partage de vos documents, photos et vidéos</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={remontees} alt="services" />
                            </div>
                            <h3>
                                Remontées d’informations
                            </h3>
                            <p><span className='bold'>Collectez les données</span> de vos collaborateurs directement sur le terrain via une tablette ou un smartphone. Soyez <span className='bold'>notifié en temps réel</span> pour pouvoir agir le plus rapidement possible et surtout pour ne pas passer à côté d’une information importante.<br></br>
                            Disposez d’une bibliothèque de divers formulaires pour gérer :</p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                            <div className="overview-box"> 
                                <div className="overview-content">
                                    <div className="content">
                                        <div className="features-text">
                                            <h4><i className="flaticon-tick"></i> Non-conformités</h4>
                                            <h4><i className="flaticon-tick"></i> Situations à risque</h4>
                                            <h4><i className="flaticon-tick"></i> Suggestions d’amélioration</h4>
                                            <h4><i className="flaticon-tick"></i> Réclamations clients</h4>
                                            <h4><i className="flaticon-tick"></i> Enquêtes de satisfaction</h4>
                                            <h4><i className="flaticon-tick"></i> Etc.</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={audit} alt="services" />
                            </div>
                            <h3>
                                Audits digitaux
                            </h3>
                            <p>Fini le crayon et le papier avec le digital vos informations sont <span className='bold'>automatiquement enregistrées</span> via votre tablette. Avec <span className='bold color-compliance'>Wispeek</span> vous pouvez réaliser vos audits de partout même sans accès à internet. Un gain de temps considérable dans <span className='bold'>le suivi et le contrôle de vos données</span> pour atteindre vos objectifs de performance. Vous pouvez également joindre des vidéos et des photos pour illustrer vos rapports.</p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                            <div className="overview-box"> 
                                <div className="overview-content">
                                    <div className="content">
                                        <div className="features-text">
                                            <h4><i className="flaticon-tick"></i> Gestion de tous vos audits internes et externes</h4>
                                            <h4><i className="flaticon-tick"></i> Formulaires de contrôle et de vérification</h4>
                                            <h4><i className="flaticon-tick"></i> Numérisation de vos études sensorielles et autres questionnaires</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={plan} alt="services" />
                            </div>
                            <h3>
                            Plans d’actions
                            </h3>
                            <p>Agissez en temps réel, dès que vous relevez une non-conformité, grâce à notre <span className='bold'>plan d’action intégré</span>. Affecter une action, prévoir une échéance, envoyer une notification, suivez en temps réel et en continu votre pan d’action.</p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                            <div className="overview-box"> 
                                <div className="overview-content">
                                    <div className="content">
                                        <div className="features-text">
                                            <h4><i className="flaticon-tick"></i> Suivi des procédures de non-conformités, réclamations, retraits et retours produits</h4>
                                            <h4><i className="flaticon-tick"></i> Suivi des actions correctives </h4>
                                            <h4><i className="flaticon-tick"></i> Planification et suivi des plans de prévention opérationnel</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 

                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={decisions} alt="services" />
                            </div>
                            <h3>
                            Outil de pilotage et de prise de décision
                            </h3>
                            <p>La plateforme permet de simplifier vos processus de management avec un <span className='bold'>suivi en temps réel de vos indicateurs de performance</span>. Un tableau de bord dynamique, précis et synthétique qui vous donne une vision globale de vos données pour optimiser vos prises de décision.</p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                            <div className="overview-box"> 
                                <div className="overview-content">
                                    <div className="content">
                                        <div className="features-text">
                                            <h4><i className="flaticon-tick"></i> Tableau de bord qui centralise vos données</h4>
                                            <h4><i className="flaticon-tick"></i> Suivi des indicateurs de performance en temps réel</h4>
                                            <h4><i className="flaticon-tick"></i> Traçabilité de vos process et procédures</h4>
                                            <h4><i className="flaticon-tick"></i> Facilite la préparation des réunions et revues de direction</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </section>
    )
}

export default ContentTwo